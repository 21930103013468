import { RouteRecordRaw } from 'vue-router';
import { createGoodsBusinessRoute } from '~/utils/route-generate';
import { individualBaseMeta, purchaseMeta, consoleBaseMeta } from '~/router/router-meta';
import { getQueryLink } from '~/utils/util';
import { ExternalCodeType } from '~/constant/goods-enum';

const constant = () => import('~/views/goods/ecology-cloud/deep-seek/constant');

export const purchase: RouteRecordRaw[] = [
  // 产品购买页面
  {
    path: 'DeepSeek',
    name: 'deep-seek-product',
    component: () => import('~/views/goods/ecology-cloud/deep-seek/Purchase.vue'),
    meta: purchaseMeta
  }
];

// 订单列表
export const order: RouteRecordRaw = createGoodsBusinessRoute<'order'>({
  path: 'DeepSeek',
  name: 'deep-seek-order-list',
  meta: individualBaseMeta,
  menuProps: { name: 'DeepSeek', show: true },
  component: 'order',
  props: {
    externalCode: ExternalCodeType.DEEP_SEEK,
    source: 2,
    jsonLabel: { path: constant, property: 'orderDetailJsonConfig' },
    paymentRoute: row => {
      const { ptId, orderNum } = row;
      const path = '/DeepSeek';
      const query = { id: ptId, orderNum };
      const link = getQueryLink({ path, query });
      return link;
    },
    navigator: {
      list: [
        { text: '订单管理' },
        { text: '生态云' },
        { text: 'DeepSeek' }
      ]
    },
  }
});

// 控制台
export const consolePage: RouteRecordRaw[] = [
  {
    path: 'DeepSeekConsole',
    name: 'deep-seek-console-view',
    redirect: '/Console/DeepSeekConsole/HostList',
    component: () => import('~/views/goods/ecology-cloud/deep-seek/Console.vue'),
    meta: consoleBaseMeta,
    children: [
      {
        path: 'HostList',
        name: 'deep-seek-console-host-list',
        component: () => import('~/views/goods/ecology-cloud/deep-seek/HostList.vue'),
        meta: consoleBaseMeta,
      },
    ]
  },

];